<template>
  <div
    class="testimonials-slider"
  >
   <swiper
     class="testimonials-slider__swiper"
     loop
     :pagination="{ clickable: true }"
     :breakpoints="swiperOptions.breakpoints"
     @swiper="onSwiper"
     @slideChange="onSlideChange"
   >
     <swiper-slide
       v-for="testimonial in testimonials"
       :key="testimonial.id"
       :virtualIndex="testimonial.id"
       class="testimonials-slider__item testimonial"
     >
        <div class="testimonial__meta">
          <div class="testimonial__meta-avatar">
            <img :src="testimonial.avatar" alt="user-avatar">
          </div>
          <div class="testimonial__meta-info">
            <h5 class="testimonial__name" v-text="testimonial.name" />
            <span class="testimonial__position" v-text="testimonial.position" />
          </div>
        </div>
        <div class="testimonial__content" v-text="testimonial.text" />
     </swiper-slide>
   </swiper>
 </div>
</template>

<script>
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import user1 from '@/assets/images/user-1.png';
import user2 from '@/assets/images/user-2.png';
import user3 from '@/assets/images/user-3.png';

require('swiper/swiper.scss');

require('swiper/components/pagination/pagination.scss');

// install Swiper modules
SwiperCore.use([Pagination]);

export default {
  name: 'TestimonialsSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          992: {
            direction: 'vertical',
            slidesPerView: '3',
            spaceBetween: '100',
          },
          768: {
            direction: 'horizontal',
            slidesPerView: '2',
            spaceBetween: '30',
          },
          320: {
            direction: 'horizontal',
            slidesPerView: '1',
            spaceBetween: '20',
          },
        },
      },
      testimonials: [
        {
          id: 1,
          avatar: user1,
          name: '1Ashley Meg',
          position: 'Web Developer at Facebook',
          text: 'Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 2,
          avatar: user2,
          name: '2Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 3,
          avatar: user3,
          name: '3Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 4,
          avatar: user3,
          name: '4Ashley Meg',
          position: 'Web Developer at Facebook',
          text: 'Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 5,
          avatar: user3,
          name: '5Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 6,
          avatar: user2,
          name: '6Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 7,
          avatar: user3,
          name: '7Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
        {
          id: 8,
          avatar: user1,
          name: '8Ashley Meg',
          position: 'Web Developer at Facebook',
          text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.testimonials-slider {

  &__swiper {
    width: 100%;
  }

  :deep {
    .testimonials-slider__item {
      width: 100%;
      max-width: 400px;
      border-radius: $border-radius * 2;
      background-color: $light;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
      overflow: hidden;

      &:nth-child(2n+2) {
        margin-left: auto;
      }

      @include breakpoint(0, $lg) {
        max-width: 100%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
      }
    }

    .swiper-container {
      padding: 40px;

      @include breakpoint(0, $lg) {
        padding: 20px;
        overflow: unset;
      }

      @include breakpoint(0, $md) {
        padding: 10px;
      }
    }

    .swiper-wrapper {
      max-height: 800px;
      justify-content: space-between;
      align-content: center;
    }

    .swiper-button-prev,
    .swiper-button-next,{
      color: $dark;
      &:after {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .swiper-pagination {

      @include breakpoint(0, $lg) {
        bottom: -20px;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid $grey-border;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        border-width: 3px;
      }
    }
  }
}

.testimonial {

  &__meta {
    padding: 15px 20px;
    border-bottom: 2px solid $grey-background;
    display: flex;
    align-items: center;

    @include breakpoint(0, $lg) {
      padding: 18px;
    }
  }

  &__meta-avatar {
    width: 42px;
    margin-right: 10px;
    flex: none;

    @include breakpoint(0, $lg) {
      width: 36px;
    }
  }

  &__name {
    margin-bottom: 0;
    font-size: 11px;
    line-height: 1.1;
    color: $grey-text;
    font-weight: 700;

    @include breakpoint(0, $lg) {
      font-size: 9px;
    }

  }

  &__position {
    font-size: 11px;
    color: $primary;
    line-height: 1.1;
    font-weight: 700;

    @include breakpoint(0, $lg) {
      font-size: 9px;
    }
  }

  &__content {
    padding: 25px 20px;
    font-size: 12px;
    line-height: 1.75;
    color: $text;
    font-family: $secondary-font-family;
    font-style: italic;

    @include breakpoint(0, $lg) {
      padding: 20px;
    }
  }

}
</style>
