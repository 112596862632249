<template>
  <div
    class="input-group"
    :class="[fieldClass]"
  >
    <input
      class="input"
      :id="id"
      :name="name"
      :type="type"
      :required="required"
    >
    <i class="helper" />
    <label class="label" :for="id" v-text="label" />
  </div>
</template>

<script>
export default {
  name: 'CheckboxField',
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    name: {
      type: String,
      require: true,
    },
    id: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasValue: false,
    };
  },
  computed: {
    fieldClass() {
      return this.type === 'radio' ? 'radio' : 'checkbox';
    },
  },
  methods: {
    defineValue(e) {
      this.hasValue = e.target.value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
.input-group {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin-right: 15px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
}

.radio {
  .helper {
    position: absolute;
    top: -2px;
    left: 0;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $primary;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid currentColor;
    }

    &:after {
      transform: scale(0);
      background-color: $primary;
      border-color: $primary;
    }
  }

  input:checked ~ .helper {
    &:after {
      transform: scale(0.5);
    }
  }

}

.checkbox {
  .helper {
    color: $primary;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    z-index: 0;
    border: 2px solid currentColor;
    border-radius: 0;
    -webkit-transition: border-color 0.28s ease;
    transition: border-color 0.28s ease;

    &:before,
    &:after {
      position: absolute;
      height: 0;
      width: 0.2rem;
      background-color: $primary;
      display: block;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-radius: 0;
      content: '';
      -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
      transition: opacity 0.28s ease, height 0s linear 0.28s;
      opacity: 0;
    }

    &:before {
      top: 0.65rem;
      left: 0.38rem;
      transform: rotate(-135deg);
      box-shadow: 0 0 0 0.0625rem #fff;
    }

    &:after {
      top: 0.3rem;
      left: 0;
      transform: rotate(-45deg);
    }
  }

  input:checked ~ .helper {
    color: $primary;

    &:before,
    &:after {
      opacity: 1;
      transition: height 0.28s ease;
    }

    &:before {
      height: 1.2rem;
      transition-delay: 0.28s;
    }
    &:after {
      height: 0.5rem;
    }
  }
}

.input {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;

  &:checked {

    & ~ .helper {
      &:before {
        color: $primary;
      }
    }

  }
}

.label {
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  text-align: left;
  display: block;
  font-size: 15px;
  color: $purple-400;
  font-family: $secondary-font-family;
}
</style>
