import MainButton from '@/components/UI/MainButton.vue';
import MainIcon from '@/components/UI/MainIcon.vue';
import InputField from '@/components/UI/InputField.vue';
import CheckboxField from '@/components/UI/CheckboxField.vue';

export default [
  MainButton,
  MainIcon,
  InputField,
  CheckboxField,
];
