<template>
  <router-link v-if="route"
     :to="link"
    class="btn"
  >
    <slot></slot>
  </router-link >
  <a v-else-if="link"
     :href="link"
     :target="target"
    class="btn"
  >
    <slot></slot>
  </a>
  <button v-else
    class="btn"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    link: String,
    route: Boolean,
    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.btn {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: 15px 45px;
  color: $light;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $secondary;
  font-family: $primary-font-family;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  text-transform: none;
  transition: opacity .15s ease-in-out,
  color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
  cursor: pointer;

  //@include breakpoint(0, $md) {
  //  font-size: 14px;
  //  width: 100%;
  //  max-width: 100%;
  //  padding-left: 20px;
  //  padding-right: 20px;
  //}

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: $light;
    opacity: 0.9;
  }

  &--primary {
    background-color: $primary;
  }

  &--secondary {
    background-color: $secondary;
  }

  &--thirty {
    background-color: $blue;
  }

  &--shadow {
    background-color: $purple-700;
    border-radius: $border-radius * 8;
    padding: 10px 40px;
    box-shadow: inset -2px 5px 0 rgba(0, 0, 0, 0.06);
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.16);
    color: $primary;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &--bordered {
    background-color: $light;
    border-radius: $border-radius * 8;
    padding: 15px 22px;
    color: $primary;
    box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.11);

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &--link {
    text-transform: none;
    padding: 0;
    color: $text;
    background-color: transparent;
    text-align: inherit;

    &:hover,
    &:focus {
      color: $accent;
      opacity: 1;
    }
  }

  &--sm {
    padding: 5px 12px;
    font-size: 12px;
    font-family: $secondary-font-family;
  }

  &--md {
    padding: 12px 80px;
    font-size: 14px;
    font-family: $secondary-font-family;

    @include breakpoint(0, $md) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include breakpoint(0, $sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &--lg {
    padding-top: 18px;
    padding-bottom: 18px;
    border-radius: 6px;
    font-size: 14px;
  }

  &--rounded {
    border-radius: 24px;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {

      path {
        transition: fill 0.2s;
      }

    }

    &:hover,
    &:focus {

      svg {

        path {
          fill: currentColor;
        }

      }

    }

  }

  &--icon-left {

    span {
      margin-left: 15px;
    }

  }

  &--icon-right {

    span {
      margin-right: 15px;
    }

  }

  &--full {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }

}
</style>
