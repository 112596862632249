<template>
  <div class="banner">
   <div class="banner__container container">
     <div class="banner__row">
          <div class="banner__col">
            <div class="banner__content">
              <div class="banner__logo">
                <img :src="logo" alt="logo">
              </div>
              <h3 v-if="firstCol.title" class="banner__title banner__title--accent" v-text="firstCol.title" />
              <p v-if="firstCol.text" class="banner__text" v-text="firstCol.text" />
            </div>
          </div>
          <div class="banner__col">
            <main-icon class="banner__icon" :name="secondCol.icon" />
            <div class="banner__content">
              <h4 v-if="secondCol.title" class="banner__title" v-text="secondCol.title" />
              <p v-if="secondCol.text" class="banner__text" v-text="secondCol.text" />
              <main-button v-if="secondCol.button" :link="secondCol.button.link"  class="banner__cta btn--primary btn--icon btn--icon-right btn--sm">
                  <span v-text="secondCol.button.caption" />
                  <main-icon name="arrow" />
              </main-button>
            </div>
          </div>
          <div class="banner__col">
            <main-icon class="banner__icon" :name="thirdCol.icon" />
            <div class="banner__content">
              <h4 v-if="thirdCol.title" class="banner__title" v-text="thirdCol.title" />
              <p v-if="thirdCol.text" class="banner__text" v-text="thirdCol.text" />
              <main-button v-if="thirdCol.button" :link="thirdCol.button.link"  class="banner__cta btn--primary btn--icon btn--icon-right btn--sm">
                  <span v-text="thirdCol.button.caption" />
                  <main-icon name="arrow" />
              </main-button>
            </div>
          </div>
       </div>
   </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo-dark.png';

export default {
  name: 'BannerSection',
  data() {
    return {
      logo,
      firstCol: {
        title: 'Stay in the know. We’ve got you covered.',
      },
      secondCol: {
        title: 'This can be a link to a press release',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisg elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim viam.',
        icon: 'reverse',
        button: {
          caption: 'Read More',
          link: '#',
        },
      },
      thirdCol: {
        title: 'Or just a short announcement',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisg elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim viam.',
        icon: 'cloud',
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.banner {
  position: relative;
  z-index: 10;
  margin-top: -30px;

  @include breakpoint(0, $lg) {
    padding-top: 70px;
    margin-top: 0;
    background-image: none !important;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 85px;
    border-radius: $border-radius * 3;
    background-color: $light;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.13);

    @include breakpoint(0, $xl) {
      padding: 40px 60px;
    }

    @include breakpoint(0, $lg) {
      flex-wrap: wrap;
      padding: 30px 20px;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.13);
    }
  }

  &__col {
    width: 31%;
    display: flex;
    align-items: flex-start;

    @include breakpoint(0, $lg) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 35px;
    }

    &:last-child {
      @include breakpoint(0, $lg) {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    width: 34px;
    margin-right: 15px;
    flex: none;

    @include breakpoint(0, $lg) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__content {
    width: 100%;
  }

  &__logo {
    margin-bottom: 12px;

    @include breakpoint(0, $lg) {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 16px;
    color: $text;
    font-family: $secondary-font-family;

    &:last-child {
      margin-bottom: 0;
    }

    &--accent {
      color: $primary;
    }
  }
  &__text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.4;
    color: $text;
    font-family: $secondary-font-family;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
