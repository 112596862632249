import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/coverage',
    name: 'Coverage',
    component: () => import(/* webpackChunkName: "coverage" */ '../views/Coverage.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/Careers.vue'),
  },
  {
    path: '/careers/:position',
    name: 'CareersSingle',
    component: () => import(/* webpackChunkName: "careers-single" */ '../views/CareersSingle.vue'),
  },
  {
    path: '/agents',
    name: 'Agents',
    component: () => import(/* webpackChunkName: "agents" */ '../views/Agents.vue'),
  },
  {
    path: '/investors',
    name: 'Investors',
    component: () => import(/* webpackChunkName: "investors" */ '../views/Investors.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
  },
  {
    path: '/blog/:post',
    name: 'BlogSingle',
    component: () => import(/* webpackChunkName: "blog-single" */ '../views/BlogSingle.vue'),
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: () => import(/* webpackChunkName: "blog-single" */ '../views/Licenses.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "terms-ofuse" */ '../views/TermsOfUse.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { el: to.hash };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from) => {
  if (from.name) {
    document.body.classList.remove(from.name.toLowerCase());
  }
  if (to.name) {
    document.body.classList.add(to.name.toLowerCase());
  }
});

export default router;
