<template>
  <div
    class="contact-card"
  >
   <div class="contact-card__content">
     <h3 class="contact-card__title" v-text="title" />
     <p class="contact-card__text" v-text="text" />
     <contact-info class="contact-card__info" />
     <socials-links class="contact-card__socials" />
   </div>
  </div>
</template>

<script>
import ContactInfo from '@/components/contact-page/Contactinfo.vue';
import SocialsLinks from '@/components/contact-page/SocialsLinks.vue';

export default {
  name: 'ContactCard',
  components: { SocialsLinks, ContactInfo },
  data() {
    return {
      title: 'Contact us',
      text: 'With fast, friendly, reliable support, we\'re happy to answer any and all questions you\'ve got!',
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.contact-card {
  padding: 50px;
  border-radius: $border-radius * 7;
  background-color: $light;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint(0, $lg) {
    padding: 35px;
  }

  @include breakpoint(0, $md) {
    padding: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 36px;
    color: $primary;
    font-family: $secondary-font-family;

    @include breakpoint(0, $lg) {
      font-size: 28px;
    }

    @include breakpoint(0, $md) {
      font-size: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 16px;
    color: $purple-400;
    font-family: $secondary-font-family;

    @include breakpoint(0, $md) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    margin-bottom: 20px;
  }

  &__socials {
    margin-top: auto;
  }
}
</style>
