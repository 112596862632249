<template>
  <div class="contact-form">
   <div class="contact-form__content">
     <h5 class="contact-form__title" v-text="title" />

     <form
       class="contact-form__body form"
       action="#"
       @submit.prevent
     >
      <input-field class="form__field" name="name" label="Name" required />
      <input-field class="form__field" type="email" name="email" label="Email" required />
      <input-field class="form__field" type="tel" name="tel" label="Phone" />

       <div class="form__group">
         <p class="form__text">Preferred method of communications</p>
         <checkbox-field class="form__checkbox" type="radio" id="communication-type-email" name="communication_type" label="Email" checked />
         <checkbox-field class="form__checkbox" type="radio" id="communication-type-phone" name="communication_type" label="Phone"  />
       </div>

      <input-field class="form__field" type="text" name="message" label="Message"  />

       <div class="form__actions">
         <main-button class="form__button btn--primary btn--rounded btn--md btn--icon btn--icon-left">
           <main-icon name="send" />
           <span>Send Message</span>
         </main-button>
       </div>
     </form>
     </div>
  </div>
</template>

<script>

export default {
  name: 'ContactForm',
  data() {
    return {
      title: 'Send us a Message',
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.contact-form {
  padding: 50px;
  border-radius: $border-radius * 7;
  background-color: $light;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);

  @include breakpoint(0, $lg) {
    padding: 35px;
  }

  @include breakpoint(0, $md) {
    padding: 25px;
  }

  &__title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 400;
    color: $purple-400;
    font-family: $secondary-font-family;

    @include breakpoint(0, $lg) {
      font-size: 18px;
    }

    @include breakpoint(0, $md) {
      font-size: 16px;
    }
  }
}

.form {
  &__group,
  &__field {
    margin-bottom: 25px;
  }
  &__text {
    font-size: 15px;
    font-family: $secondary-font-family;
    margin-bottom: 11px;
  }
  &__actions {
    text-align: center;
  }
  &__button {
    @include breakpoint(0, $md) {
      width: 100%;
    }
  }
}
</style>
