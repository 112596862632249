<template>
  <div class="promo">
    <div
      v-if="image"
      class="promo__bg"
      :style="{backgroundImage: `url(${image})`}"
    />
    <div class="promo__container container">
     <div class="promo__content">
       <h3 v-if="subtitle" class="promo__subtitle" v-html="subtitle" />
       <h1 class="promo__title" v-html="title" />
       <div v-if="text" class="promo__text" v-html="text" />
       <div class="promo__actions">
         <main-button v-if="button" :route="button.route" :link="button.link" class="promo__cta btn--secondary" :class="{'btn--icon btn--icon-left': button.icon}">
            <main-icon v-if="button.icon" :name="button.icon" />
           <span>{{ button.caption }}</span>
         </main-button>
         <main-button v-else class="promo__cta btn--secondary btn--icon btn--icon-left">
           <main-icon name="messages" />
           <span>GET STARTED</span>
         </main-button>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'PromoSection',
  props: {
    subtitle: String,
    title: String,
    text: String,
    image: String,
    button: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 170px 0 75px;
  background-color: $primary;

  @include breakpoint(0, $lg) {
    position: relative;
    padding: 150px 0 280px;
  }

  &--home {
    min-height: 100vh;

    @include breakpoint(0, $xl) {
      min-height: 30px;
      padding-bottom: 170px;
    }

    .promo__bg {
      @include breakpoint(0, $xxl) {
        width: 40%;
      }
      @include breakpoint(0, $lg) {
        width: 100%;
        opacity: 0.14;
        background-position: 50% 100%;
      }
      @include breakpoint(0, $md) {
        background-position: 0 100%;
        background-size: auto;
      }
    }

    .promo__title {
      font-weight: 700;
    }
  }

  &--careers {

    .promo__bg {
      background-position: 90% 80%;

      @include breakpoint(0, $xl) {
        background-position: 100% 100%;
      }

      @include breakpoint(0, $sm) {
        width: 80%;
        left: auto;
      }
    }

    .promo__actions {
      @include breakpoint(0, $sm) {
        text-align: center;
      }
    }
  }

  &--licenses {

    .promo__bg {
      background-position: 90% 80%;

      @include breakpoint(0, $xl) {
        background-position: 100% 100%;
      }

      @include breakpoint(0, $sm) {
        width: 60%;
        left: auto;
      }
    }
  }
  &--investors {

    .promo__bg {
      background-position: 90% 70%;

      @include breakpoint(0, $xl) {
        background-position: 90% 95%;
      }

      @include breakpoint(0, $sm) {
        width: 60%;
      }
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 5;

    background-position: 100% 100%;
    background-repeat: no-repeat;

    @include breakpoint(0, $xxl) {
      height: 70%;
      width: 30%;
      background-size: contain;
    }

    @include breakpoint(0, $lg) {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    width: 60%;

    @include breakpoint(0, $lg) {
      width: 100%;
    }
  }

  &__subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    color: $light;
    font-weight: 400;
    text-transform: uppercase;

    @include breakpoint(0, $lg) {
      font-size: 18px;
    }

    @include breakpoint(0, $md) {
      font-size: 16px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 50px;
    color: $light;
    font-weight: 500;

    @include breakpoint(0, $xxl) {
      font-size: 40px;
    }

    @include breakpoint(0, $lg) {
      font-size: 36px;
    }

    @include breakpoint(0, $md) {
      font-size: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    br {
      @include breakpoint(0, $lg) {
        display: none;
      }
    }
  }

  &__text {
    margin-bottom: 50px;
    font-size: 22px;
    color: $light;

    @include breakpoint(0, $lg) {
      margin-bottom: 25px;
      font-size: 20px;
    }

    @include breakpoint(0, $md) {
      font-size: 18px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    :deep {
      p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }
}
</style>
