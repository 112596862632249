<template>
  <div
    class="contact-map bg-cover"
    :style="{backgroundImage: `url(${map})`}">
   <div class="contact-map__container container">
     <div class="contact-map__address">
       <div class="contact-map__text" v-html="address" />
       </div>
   </div>
  </div>
</template>

<script>
import map from '@/assets/images/map.png';

export default {
  name: 'ContactMap',
  data() {
    return {
      map,
      address: `
                55578 Ave de Las Banderas, Suite 257 </br>
                Rancho Santa Margarita, CA 92688
                `,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
.contact-map {
  position: relative;
  border-radius: $border-radius * 7;
  background-color: $light;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);

  &__address {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 0;
    z-index: 10;
  }

  &__text {
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    max-width: 250px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 1.2;
    color: $purple-400;
    font-family: $secondary-font-family;
    background-color: $light;
  }
}
</style>
