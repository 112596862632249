<template>
     <ul class="socials list-unstyled">
       <li
         v-for="social in socials"
         :key="social.name"
           class="socials__item"
       >
         <a class="socials__link" :href='social.url' target="_blank">
           <main-icon class="socials__icon" :name="social.name" />
           <span>{{social.name}}</span>
         </a>
       </li>
     </ul>
</template>

<script>
export default {
  name: 'SocialsLinks',
  data() {
    return {
      socials: [
        {
          name: 'facebook',
          url: '#',
        },
        {
          name: 'instagram',
          url: '#',
        },
        {
          name: 'twitter',
          url: '#',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.socials {
  display: flex;

  &__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    width: 20px;
  }

  &__link {
    font-size: 0;
  }

}
</style>
