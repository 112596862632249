<template>
  <div class="input-group">
    <input
      class="input"
      :class="{ 'has-value': hasValue, }"
      :id="name"
      :name="name"
      :type="type"
      :required="required"
      @change="defineValue"
    >
    <label class="label" :for="name" v-text="label" />
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasValue: false,
    };
  },
  methods: {
    defineValue(e) {
      this.hasValue = e.target.value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
.input-group {
  position: relative;
  padding-top: 15px;
}

.input {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid $primary;
  outline: none;
  font-family: $secondary-font-family;
  box-shadow: none;
  line-height: 1.9;
  &:focus,
  &.has-value {
    outline: none;

    & ~ .label {
      color: $primary;
      top: 5px;
      font-size: 12px;
    }
  }
}

.label {
  position: absolute;
  top: 30px;
  left: 0;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  font-size: 15px;
  color: $purple-400;
  font-family: $secondary-font-family;
}
</style>
