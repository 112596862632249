<template>
     <ul class="contact-info list-unstyled">
       <li class="contact-info__item" v-if="mail">
         <main-icon class="contact-info__icon" name="mail" />
         <a class="contact-info__link" :href='"mailto:" + mail' v-text="mail" />
       </li>
       <li class="contact-info__item" v-if="tel">
         <main-icon class="contact-info__icon" name="tel" />
         <a class="contact-info__link" :href='"tel:" + tel' v-text="tel" />
       </li>
     </ul>
</template>

<script>
export default {
  name: 'ContactInfo',
  data() {
    return {
      mail: 'info@portfolio10.lynxweb.tech/',
      tel: '+1 555 656 7777',
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.contact-info {

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 20px;
    flex:  none;
    margin-right: 8px;
  }

  &__link {
    font-size: 15px;
    color: $purple-400;
    font-family: $secondary-font-family;
    text-decoration: none;
  }

}
</style>
