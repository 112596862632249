<template>
  <div class="">
    <promo-section
      class="promo--home"
      :title="promo.title"
      :text="promo.text"
      :image="promo.image"
    />
    <banner-section />
    <image-context />
    <slider-section />
    <testimonials-section />
    <contacts-section />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import BannerSection from '@/components/BannerSection.vue';
import ImageContext from '@/components/home-page/ImageContext.vue';
import SliderSection from '@/components/home-page/SliderSection.vue';
import TestimonialsSection from '@/components/home-page/TestimonialsSection.vue';
import ContactsSection from '@/components/ContactsSection.vue';
import promoImage from '@/assets/images/home-promo.svg';

export default {
  name: 'Home',
  components: {
    TestimonialsSection,
    SliderSection,
    ImageContext,
    BannerSection,
    PromoSection,
    ContactsSection,
  },
  data() {
    return {
      promo: {
        title: 'The most important innovation for truckers <span class="text-accent">since coffee</span>',
        text: '<p>Packed with <span class="text-accent">free loss control</span> and <span class="text-accent">coaching services</span> for drivers. We have multiple programs to support a <span class="text-accent">wide range of trucking operations</span> with in-house binding authority</p>',
        image: promoImage,
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.home {
  .banner {
    @include breakpoint(0, $lg) {
      margin-top: -80px;
      padding-top: 0;
    }
  }
}
</style>
