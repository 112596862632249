<template>
  <div
    class="slider"
    :style="{backgroundImage: `url(${decorTriangle})`}"
  >
   <div class="slider__container container">
     <h2 class="slider__title" v-text="title" />
     <div class="slider__row">
       <div class="slider__image">
         <img :src="sliderImage" alt="">
       </div>
       <swiper
         class="slider__swiper"
         :slides-per-view="1"
         :space-between="0"
         navigation
         loop
         :pagination="{ clickable: true }"
         @swiper="onSwiper"
         @slideChange="onSlideChange"
       >
         <swiper-slide
           v-for="(slide, index) in slides"
           :key="slide.id"
           :virtualIndex="slide.id"
           class="slider__item slide"
         >
           <div class="slide__content">
             <div class="slide__num">0{{index+1}}</div>
             <h3 class="slide__title" v-text="slide.title" />
             <p class="slide__text" v-text="slide.text" />
           </div>
         </swiper-slide>
       </swiper>
     </div>
   </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import sliderImage from '@/assets/images/slide-image.png';
import decorTriangle from '@/assets/images/decor-triangle.svg';

require('swiper/swiper.scss');

require('swiper/components/navigation/navigation.scss');

require('swiper/components/pagination/pagination.scss');

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'SliderSection',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        grabCursor: true,
        watchOverflow: true,
        resistanceRatio: 0,
        loop: true,
        pagination: false,
        navigation: false,
        breakpoints: {
          992: {
            pagination: false,
            navigation: false,
          },
        },
      },
      sliderImage,
      decorTriangle,
      title: 'Getting covered is literally as easy as 1, 2, 3.',
      slides: [
        {
          id: 1,
          title: 'Give us 2 minutes of your time.',
          text: 'Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 2,
          title: '22Give us 2 minutes of your time.',
          text: '33Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
        {
          id: 3,
          title: '33Give us 2 minutes of your time.',
          text: '33Our platform was built on years of industry experience, direct feedback and advice from brokers who use it. We’ve honed the experience to be as easy and fast for brokers as possible',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.slider {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: 90% 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
    background-image: none !important;
  }

  @include breakpoint(0, $md) {
    padding: 25px 0;
    background-image: none !important;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0 auto;
    border-radius: $border-radius * 9.5;
    background-color: rgba($light, 0.8);
    box-shadow: 5px 6px 52px rgba(0, 0, 0, 0.13);
    overflow: hidden;
    @include breakpoint(0, $lg) {
      flex-direction: column-reverse;
    }

  }

  &__image {
    position: relative;
    width: 50%;
    flex: none;
    margin-right: 15px;

    @include breakpoint(0, $lg) {
      width: 100%;
      margin-right: 0;
      padding-top: 15px;
      padding-right: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 90%;
      width: 1px;
      background-color: rgba($grey-border, 0.24);

      @include breakpoint(0, $lg) {
        height: 1px;
        width: 100%;
        top: 0;
        transform: translateY(0);
      }
    }
  }

  &__swiper {
    width: 100%;
  }

  :deep {
    .slider__item {
      width: 50%;
      padding: 25px 85px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(0, $lg) {
        width: 100%;
        padding: 40px;
      }

      @include breakpoint(0, $md) {
        padding: 40px 30px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next,{
      color: $dark;
      &:after {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid $text;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        background-color: $text;
      }
    }

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      @include breakpoint(0, $lg) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 50px;
    font-size: 36px;
    color: $dark;
    text-align: center;

    @include breakpoint(0, $lg) {
      margin-bottom: 40px;
      font-size: 28px;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 30px;
      font-size: 24px;
    }
  }
}

.slide {
  &__num {
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: 700;
    color: $primary;
    line-height: 1.1;

    @include breakpoint(0, $lg) {
      font-size: 40px;
    }

    @include breakpoint(0, $md) {
      font-size: 36px;
    }
  }
  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    color: $primary;
    font-family: $secondary-font-family;
    text-transform: uppercase;

    @include breakpoint(0, $lg) {
      font-size: 18px;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.4;
    color: $primary;
    font-family: $secondary-font-family;

    @include breakpoint(0, $md) {
      font-size: 14px;
    }
  }
}
</style>
