<template>
  <div class="contacts">
   <div class="contacts__container container">
     <div class="contacts__row">
       <contact-card class="contacts__card" />
       <contact-map class="contacts__map" />
       <contact-form class="contacts__form" />
       </div>
   </div>
  </div>
</template>

<script>
import ContactCard from '@/components/contact-page/ContactCard.vue';
import ContactForm from '@/components/contact-page/ContactForm.vue';
import ContactMap from '@/components/contact-page/ContactMap.vue';

export default {
  name: 'ContactsSection',
  components: {
    ContactCard,
    ContactForm,
    ContactMap,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.contacts {
  padding: 70px 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
  }

  @include breakpoint(0, $lg) {
    padding: 25px 0;
  }

  &__row {
    max-width: 1220px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @include breakpoint(0, $lg) {
      display: block;
    }
  }

  &__card {
    @include breakpoint(0, $lg) {
      margin-bottom: 20px;
    }
  }

  &__map {
    @include breakpoint(0, $lg) {
      margin-bottom: 20px;
    }
  }

  &__card {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
  }

  &__map {
    grid-column: 1 / 2;
    grid-row: 4 / -1;

    @include breakpoint(0, $lg) {
      height: 350px;
    }
  }

  &__form {
    grid-column: 2 / 3;
    grid-row: 1 / -1;
  }
}
</style>
