<template>
  <div
    class="testimonials"
    :style="{backgroundImage: `url(${decorLines})`}"
  >
   <div class="testimonials__container container">
     <div class="testimonials__row">
          <div class="testimonials__content">
            <h2 class="testimonials__title" v-text="title" />
            <div class="testimonials__text" v-html="text" />
            <div class="testimonials__icon">
              <img :src="testimonialsIcon" alt="flow icons">
            </div>
          </div>
        <testimonials-slider class="testimonials__slider" />
       </div>
   </div>
  </div>
</template>

<script>
import testimonialsIcon from '@/assets/images/testimonials-icon.svg';
import TestimonialsSlider from '@/components/home-page/TestimonialsSlider.vue';
import decorLines from '@/assets/images/decor-lines.png';

export default {
  name: 'TestimonialsSection',
  components: { TestimonialsSlider },
  data() {
    return {
      decorLines,
      testimonialsIcon,
      title: 'There\'s the old wqy, and the Cover Whale way.',
      text: 'This section talks about the customer first mentality that CoverWhale has. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua.',
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.testimonials {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: 0 50%;
  overflow: hidden;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
    background-image: none !important;
  }

  @include breakpoint(0, $md) {
    padding: 25px 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include breakpoint(0, $lg) {
      flex-direction: column;
    }
  }

  &__icon {
    text-align: center;
    margin-top: 100px;

    @include breakpoint(0, $lg) {
      margin-top: 25px;
    }
  }

  &__content {
    width: 50%;
    padding-left: 50px;
    max-width: 600px;

    @include breakpoint(0, $lg) {
      width: 100%;
      margin-bottom: 25px;
      padding-left: 0;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 36px;
    color: $text;

    @include breakpoint(0, $lg) {
      font-size: 28px;
    }

    @include breakpoint(0, $md) {
      font-size: 24px;
    }

  }

  &__text {
    font-size: 16px;
    color: $text;
    font-family: $secondary-font-family;

    @include breakpoint(0, $md) {
      font-size: 14px;
    }
  }

  &__slider {
    width: 50%;

    @include breakpoint(0, $lg) {
      width: 100%;
    }
  }
}
</style>
