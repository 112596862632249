<template>
  <site-header />
  <main id="main" class="main">
    <router-view/>
  </main>
  <site-footer />
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
};
</script>

<style lang="scss">
#app {
  //min-height: 100vh;
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: auto 1fr auto;
  //grid-template-areas:
  //  'header'
  //  'main'
  //  'footer';
  //
  //header {
  //  grid-area: header;
  //}
  //main {
  //  grid-area: main;
  //}
  //footer {
  //  grid-area: footer;
  //}
}

</style>
