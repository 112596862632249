<template>
  <header class="header">
    <div class="header__row">
      <div class="header__logo">
        <router-link class="nav__link" to="/">
          <img :src="logo" alt="">
        </router-link>
      </div>
      <button
        class="header__burger btn btn--link"
        :class="{ active: menuActive }"
        @click="toggleMenu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <transition name="fade">
        <div v-show="menuActive" class="header__menu">
          <nav @click="menuActive = false" class="header__navigation nav" id="nav">
            <router-link class="nav__link" to="/about">About</router-link>
            <router-link class="nav__link" to="/coverage">Coverage</router-link>
            <router-link class="nav__link" to="/agents">Agents</router-link>
            <router-link class="nav__link" to="/investors">Investors</router-link>
            <router-link class="nav__link" to="/careers">Careers</router-link>
            <router-link class="nav__link" to="/blog">Press</router-link>
            <router-link class="nav__link" to="/licenses">Licenses</router-link>
            <router-link class="nav__link nav__link--button" to="/contact">Get in Touch</router-link>
          </nav>
        </div>
      </transition>

    </div>
  </header>
</template>

<script>
import logo from '@/assets/images/logo.png';

export default {
  name: 'SiteHeader',
  data() {
    return {
      logo,
      menuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.menuActive = false;
    });
    window.addEventListener('scroll', () => {
      this.menuActive = false;
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    padding: 50px 70px;

    @media (max-width: 1200px) {
      padding: 30px;
    }

    @include breakpoint(0, $lg) {
      padding: 20px 30px;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__burger {
      position: relative;
      display: none;
      margin-left: auto;
      height: 28px;
      width: 35px;

      @include breakpoint(0, $lg) {
        display: inline-flex;
      }

      span {
        display: inline-flex;
        vertical-align: middle;
        transition: all .25s;
        position: absolute;
        left: 0;
        top: 0;
        height: 3px;
        width: 100%;
        background-color: $light;
        cursor: pointer;

        &:nth-child(2) {
          top: 13px;
        }

        &:nth-child(3) {
          top: 26px;
        }
      }

      &.active {

        span {
          top: 14px;

          &:nth-child(1){
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            background-color: transparent;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }

        }

      }

    }

    &__menu {
      @include breakpoint($lg) {
        display: block !important;
      }
      @include breakpoint(0, $lg) {
        //display: none;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        min-height: calc(100vh - 70px);
        padding: 16px 0;
        z-index: 999;

        background: $primary;
      }
    }
  }

  .nav {
    @include breakpoint(0, $lg) {
      display: flex;
      flex-direction: column;
      padding: 30px;
    }

    &__link {
      display: inline-block;
      vertical-align: middle;
      margin-right: 50px;
      color: $light;
      font-size: 18px;
      font-family: $primary-font-family;
      font-weight: 500;
      text-decoration: none;

      @include breakpoint(0, $xl) {
        margin-right: 25px;
      }

      @media (max-width: 1200px) {
        margin-right: 20px;
        font-size: 16px;
      }

      @include breakpoint(0, $lg) {
        margin-right: 0;
        margin-bottom: 15px;
        text-align: center;
        font-size: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &--button {
        @include btn($light, transrapent, 2px, $light, 12px, 7px 25px);

        @include breakpoint(0, $lg) {
          align-self: center;
        }
      }
    }
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
