<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__top">
        <div class="footer__meta">
          <div class="footer__logo">
            <router-link class="nav__link" to="/">
              <img :src="logo" alt="">
            </router-link>
          </div>
          <contact-info class="footer__info" />
        </div>
        <div class="footer__links">
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/about">About</router-link>
            <router-link class="nav__link" to="/about#team">Team</router-link>
            <router-link class="nav__link" to="/about#process">Our Process</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/coverage">Coverage</router-link>
            <router-link class="nav__link" to="/coverage#plans">Automobile Liability</router-link>
            <router-link class="nav__link" to="/coverage#plans">Physical Damage</router-link>
            <router-link class="nav__link" to="/coverage#plans">Cargo</router-link>
            <router-link class="nav__link" to="/coverage#plans">Truckers General Liability</router-link>
            <router-link class="nav__link" to="/coverage#plans">Non-Trucking Liability Insurance</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/agents">Agents</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/investors">Investors</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/careers">Careers</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/contact">Contact</router-link>
          </div>
          <div class="footer__col nav">
            <router-link class="nav__link nav__link--lg" to="/">Login</router-link>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="footer__policy">
            <router-link class="nav__link" to="/licenses">Licenses</router-link>
            <router-link class="nav__link" to="/privacy-policy">Privacy Policy</router-link>
            <router-link class="nav__link" to="/terms-of-use">Terms of Use</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import logo from '@/assets/images/logo-dark.png';
import ContactInfo from '@/components/contact-page/Contactinfo.vue';

export default {
  name: 'SiteFooter',
  components: { ContactInfo },
  data() {
    return {
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
  .footer {

    &__top {
      padding: 70px 0;

      display: flex;
      justify-content: space-between;

      @include breakpoint(0, $lg) {
        padding: 35px 0;
        flex-wrap: wrap;
      }
    }

    &__bottom {
      padding: 30px 0;

      @include breakpoint(0, $lg) {
        padding: 20px 0;
      }
    }

    &__logo {
      margin-bottom: 35px;
    }

    &__info {
      @include breakpoint(0, $lg) {
        display: none;
      }
    }

    &__links {
      display: flex;
      justify-content: space-between;
      width: 70%;

      @include breakpoint(0, $lg) {
        width: 100%;
        display: block;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;

      @include breakpoint(0, $lg) {
        margin-bottom: 25px;
      }

      &:last-child {
        @include breakpoint(0, $lg) {
          margin-bottom: 0;
        }
      }

      .nav__link {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__policy {
      text-align: right;

      @include breakpoint(0, $lg) {
        text-align: center;
      }

      .nav__link {
        position: relative;
        padding-right: 50px;

        @include breakpoint(0, $lg) {
          padding-right: 25px;
        }

        @include breakpoint(0, $sm) {
          padding-right: 18px;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 5px;
          right: 24px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: currentColor;

          @include breakpoint(0, $lg) {
            right: 12px;
          }

          @include breakpoint(0, $sm) {
            right: 8px;
          }
        }
      }
    }

  }

.nav {

  &__link {
    text-decoration: none;
    color: $grey-200;
    font-size: 14px;
    font-weight: 700;

    &--lg {
      font-size: 16px;
    }
  }

}
</style>
