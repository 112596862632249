<template>
  <div class="image-context">
   <div class="image-context__container container">
     <div class="image-context__row">
          <div class="image-context__image">
            <img :src="flowIcons" alt="flow icons">
          </div>
          <div class="image-context__content">
            <h2 class="image-context__title" v-text="title" />
            <h3 class="image-context__subtitle" v-text="subtitle" />
            <div class="image-context__text" v-html="text" />
          </div>
       </div>
   </div>
  </div>
</template>

<script>
import flowIcons from '@/assets/images/flow-icons.svg';

export default {
  name: 'ImageContext',
  data() {
    return {
      flowIcons,
      title: 'There\'s the old wqy, and the Cover Whale way.',
      subtitle: 'We’re repaving the insurance landscape one mile at a time.',
      text: `
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <p>
                We are constantly making our products smarter, faster, and easier to use. We analyze new sources of data with machine learning techniques and listen to our customers’ feedback to innovate our products and tools. We deliver novel insights to our broker partners, helping to make them smarter about complex risks their clients face.
            </p>
            <p>
                We’re transforming the industry by bringing coverage options to owner/operators that were too difficult for conventional insurance companies to write.
            </p>
      `,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.image-context {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $md) {
    padding: 35px 0 25px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(0, $lg) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__content {
    width: 50%;
    padding-left: 50px;

    @include breakpoint(0, $lg) {
      width: 100%;
      margin-bottom: 40px;
      padding-left: 0;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 30px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 40px;
    color: $primary;

    background: -webkit-linear-gradient(45deg, #613675, #CA64DC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include breakpoint(0, $lg) {
      font-size: 36px;
    }

    @include breakpoint(0, $md) {
      font-size: 28px;
    }

  }

  &__subtitle {
    margin-bottom: 40px;
    font-size: 28px;
    color: $dark;

    @include breakpoint(0, $lg) {
      margin-bottom: 20px;
      font-size: 24px;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 15px;
      font-size: 20px;
    }

  }

  &__text {
    font-size: 18px;
    line-height: 1.4;
    color: $text;
    font-family: $secondary-font-family;

    @include breakpoint(0, $lg) {
      font-size: 16px;
    }

    :deep {
      p {
        position: relative;
        margin-bottom: 20px;
        font-size: inherit;
        line-height: inherit;
        color: inherit;

        @include breakpoint(0, $lg) {
          margin-bottom: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          position: absolute;
          left: -33px;
          top: 10px;
          width: 16px;
          height: 7px;
          border-radius: $border-radius;
          background-color: $purple-200;

          @include breakpoint(0, $lg) {
            display: none;
          }
        }

        &:nth-child(2n) {
          &:before {
            background-color: $purple-200;
          }
        }

        &:nth-child(3n) {
          &:before {
            background-color: $purple-300;
          }
        }
      }
    }
  }
}
</style>
